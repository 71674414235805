import { adminRole, salesAgentRole } from "./app_constants";

export const loginRoute = "/";

export const publicLocationRoute = `/location`;
export const publicSolarRoute = `/map`;
export const overviewRoute = `/${adminRole}/overview`;
export const dashboardRoute = `${adminRole}/dashboards`;
export const sitesRoute = `${adminRole}/all_sites_map`;
export const sitesTableRoute = `${adminRole}/all_sites_list`;

export const monitoringRoute = `${adminRole}/monitoring`;
export const settingsRoute = `${adminRole}/settings`;
export const devicesRoute = `${adminRole}/devices`;
export const reportingRoute = `${adminRole}/reporting`;
export const projectsRoute = `${adminRole}/projects`;
export const environmentalRoute = `${adminRole}/environmental`;
export const financialRoute = `${adminRole}/financial`;
export const customMix1Route = `${adminRole}/custom_mix_1`;
export const euOnlyRoute = `${adminRole}/eu_only`;
export const usaOnlyRoute = `${adminRole}/usa_only`;
export const pagesRoute = `${adminRole}/pages`;
export const monitoringSingleSiteRoute = `${adminRole}/monitoring_single_site`;
export const FuelMixRoute = `${adminRole}/fuel_mix`;
export const NewSiteRoute = `${adminRole}/new_site`;
export const AccessNewRoute = `${adminRole}/access_new_site`;
export const integrationsAPIRoute = `${adminRole}/integrations_api`;
export const enerygyInsightsRoute = `${adminRole}/energy_insights`;
export const savedOpportunitiesRoute = `/saved_opportunities/:id`;

export const openSolarRoute = `${adminRole}/opensolar`;
export const openSolarLoginRoute = `${adminRole}/opensolar/login`;
export const openSolarProjectsRoute = `${adminRole}/opensolar/projects`;
export const savedOpportunitiesRouteLink = `/saved_opportunities/`;
// export const savedOpportunitiesRouteLink = `${adminRole}/saved_opportunities/`;
export const dashboardRouteMenu = `${adminRole}/dashboards`;

export const designRoute = `${adminRole}/design`;

export const AlertEmailsTabRoute = `${adminRole}/alert_emails`;

export const businessesTableRoute = `/${adminRole}/businesses`;

// Site Settings
export const siteBillDetailsRoute = `${adminRole}/site/bill_details`;
export const SiteSettingsTabRoute = `${adminRole}/site_settings`;
export const siteAddBillRoute = `${adminRole}/site/add_bill`;

// Bills
export const billsRoute = `${adminRole}/bills`;
export const addBNewBillRoute = `${adminRole}/add_bill`;
export const billDetailsRoute = `${adminRole}/bill_details`;

// Prospect
export const prospectDemoRoute = `${adminRole}/search_prospect`;
export const savedProspectsRoute = `${adminRole}/saved_prospects`;
export const liveProspectSitesRoute = `${adminRole}/prospect_sites`;
export const prospectDemoAgentRoute = `${salesAgentRole}/search_prospect`;
export const savedProspectsAgentRoute = `${salesAgentRole}/saved_prospects`;
export const liveProspectSitesAgentRoute = `${salesAgentRole}/prospect_sites`;

// Hot Lead
export const hotLeadAgentRoute = `${salesAgentRole}/hot_lead`;
export const hotLeadAdminRoute = `${adminRole}/hot_lead`;
export const hotLeadDetailsAdminRoute = `${salesAgentRole}/hot_lead_details`;
export const hotLeadDetailsAgentRoute = `${adminRole}/hot_lead_details`;

export const mapLiveProspectSitesAgentRoute = `${salesAgentRole}/search_prospect/map`;

export const contractPreparedRoute = `${salesAgentRole}/contracts_prepared`;

export const contractSignedRoute = `${salesAgentRole}/contracts_signed`;