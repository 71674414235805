// export const baseURL="http://localhost:5000" 
export const baseURL="https://api.inverters.greenwallets.ai"
export const loginRoute= "https://api.staging.greenwallets.ai/api/shopify/login"
export const productionDataRoute= `${baseURL}/voltflo/get_production_data`
export const updateCompanyNameRoute= `${baseURL}/voltflo/update_company_name`
export const addSettingsRoute= `${baseURL}/voltflo/create_update_settings` 
export const getReportRoute= `${baseURL}/voltflo/get_report` 
export const getSettingsRoute= `${baseURL}/voltflo/get_settings_data` 
export const getCarbonDailyData= `${baseURL}/huawei/daily_data` 
export const getLast30DaysData= `${baseURL}/huawei/monthly_data` 


export const getUsersAllInverters= `${baseURL}/users/getAllInverters` 
export const deleteInverter= `${baseURL}/users/delete-inverter` 

export const getHuaweiDailyDataRoute= `${baseURL}/huawei/daily_data` 
export const getHuaweiWeeklyDataRoute= `${baseURL}/huawei/weekly_data` 
export const getHuaweiMonthlyDataRoute= `${baseURL}/huawei/monthly_data` 

export const getAllInvertersDailyDataRoute= `${baseURL}/all_inverter/daily_data` 
export const getAllInvertersWeeklyDataRoute= `${baseURL}/all_inverter/weekly_data` 
export const getAllInvertersMonthlyDataRoute= `${baseURL}/all_inverter/monthly_data` 

export const getSolisDailyDataRoute= `${baseURL}/solis/daily_data` 
export const getSolisWeeklyDataRoute= `${baseURL}/solis/weekly_data` 
export const getSolisMonthlyDataRoute= `${baseURL}/solis/monthly_data` 

export const getEnphaseDailyDataRoute= `${baseURL}/enphase/daily_data` 
export const getEnphaseWeeklyDataRoute= `${baseURL}/enphase/weekly_data` 
export const getEnphaseMonthlyDataRoute= `${baseURL}/enphase/monthly_data` 

// Open Solar
export const loginOpensolarRoute= `${baseURL}/voltflo/opensolar_login` 
export const logoutOpensolarRoute= `${baseURL}/voltflo/opensolar_logout` 
export const OpensolarProjectDetailsRoute= `${baseURL}/voltflo/project_details` 
export const OpensolarUpdateDataRoute= `${baseURL}/voltflo/opensolar_update_data` 
export const OpensolarGetProjectsRoute= `${baseURL}/voltflo/get_opensolar_projects` 
export const OpensolarLoggedInUserRoute= `${baseURL}/voltflo/get_user_logged_in` 
export const OpensolarLoggedInSiteRoute= `${baseURL}/voltflo/get_site_logged_in` 

// Google Solar
export const GoogleSolarAddRoute= `${baseURL}/voltflo/add_google_solar` 


// Bills
export const getUserBillsRoute= `${baseURL}/voltflo/get_user_bills` 
export const getBillDetailsRoute= `${baseURL}/voltflo/get_bill_details` 
export const addBillRoute= `${baseURL}/voltflo/add_bill` 
export const deleteBillRoute= `${baseURL}/voltflo/delete_bill` 
export const updateBillRoute= `${baseURL}/voltflo/update_bill` 
export const updateBillBySiteRoute= `${baseURL}/voltflo/add_site_by_bill` 
export const getLastBillRoute= `${baseURL}/voltflo/get_last_bill` 

// Alert Emails
export const getEmailsRoute= `${baseURL}/voltflo/get_emails` 
export const updateEmailsRoute= `${baseURL}/voltflo/update_emails` 
export const sendEmailsRoute= `${baseURL}/voltflo/send_email` 

// Sites
export const addNewSiteRoute= `${baseURL}/voltflo/add_site` 
export const updateSiteRoute= `${baseURL}/voltflo/update_site` 
export const deleteSiteRoute= `${baseURL}/voltflo/delete_site` 
export const getAllSitesRoute= `${baseURL}/voltflo/get_all_sites` 
export const getSiteDataRoute= `${baseURL}/voltflo/get_site_data` 
export const addSiteRoute= `${baseURL}/voltflo/add_new_site` 
export const getSiteSampleRoute= `${baseURL}/voltflo/get_site_sample` 

// Contracts
export const getDocusealToken= `${baseURL}/voltflo/docuseal-token` 
