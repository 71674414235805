import axios from "axios";
import isTokenExpired from "src/services/auth_header";

const apiBaseUrl =
  process.env.NODE_ENV === 'production'  
    ? 
    "https://api.inverters.greenwallets.ai"
    : 
    "http://localhost:5000/";
 
// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: apiBaseUrl,
  // headers: headers
  // You can add more configuration options here, such as headers, interceptors, etc.
});

if (typeof window !== "undefined") {
  const getInfoLocal = JSON.parse(localStorage.getItem("userData"));

  if (getInfoLocal?.accessToken && !isTokenExpired()) {
    api.defaults.headers.common["Authorization"] = getInfoLocal?.accessToken;
  }
}

export default api;
