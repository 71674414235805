import React from "react";
import ContextData from "../hooks/useAuth";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import isTokenExpired from "src/services/auth_header";

const RequireAuth = ({ allowedRoles }) => {
  var { auth } = ContextData();
  const location = useLocation();
  auth = JSON.parse(localStorage.getItem("userData"))


  return auth?.role?.find((role) => allowedRoles?.includes(role)) !==
    undefined  && auth?.role?.find((role) => allowedRoles?.includes(role))  && !isTokenExpired() ? (
    <Outlet />
  ) : auth?.accessToken && !isTokenExpired() ? (
    <Navigate to={"/unauthorised"} state={{ from: location }} replace />
  ) : (
    <Navigate to={"/"} state={{ from: location }} replace /> 
  );
};

export default RequireAuth;
