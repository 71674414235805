import { delete_cookie } from "sfcookies";
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";

// const getCookie = (name) => { 
  
//   const cookieValue = document.cookie.match(
//     "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
//   );
//   const cookieData = cookieValue ? cookieValue.pop() : "";
//   const data_list = Object.keys(cookieData).length > 0 ? JSON.parse(decodeURIComponent(cookieData)) : {};
//   return data_list;
// };
 

// const isTokenExpired1 = ()  => {
//   const token = JSON.parse(localStorage.getItem("userData"));
  
//   if(token){
//     // console.log("here", token)
//     // const tokenData = jwtDecode("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwdWJsaWNfaWQiOiJlYmI0NjljYy00Y2Y2LTRmM2UtOGJmYi0xZjY0MmMzZTIwZTUiLCJleHAiOjE3MTMzNTM2NzR9.n_NYjdQjm7Sz_3gzYg8B5H-0OG8MUJy0jmAvi7Kssnw");
//     // const tokenData = jwtDecode(token?.accessToken);
//     // const expirationTimestamp = tokenData.exp;
    
//     const currentTimestamp = Date.now() / 1000;
//     const expirationTimestamp = (Date.now() / 1000) + (10 * 60); 
//     // console.log("Exp: ", expirationTimestamp, currentTimestamp )
//     const isExpired =currentTimestamp > expirationTimestamp
//     if (isExpired) {
//       alert("Token has expired, Please Login again" )
//       delete_cookie("auth");
//       delete_cookie("token");
//       window.location.href= "/"
//       document.cookie = "userDetails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
//       return true;
//     } else {
//       return false;
//     } 
//   }
//   else{
//     return true
//   }
 
// }

const isTokenExpired = () => {
  const data = JSON.parse(localStorage.getItem("userData"));
  if (data && data?.accessToken) {
    const tokenData = jwtDecode(data?.accessToken);
    const expirationTimestamp = tokenData.exp;
    const currentTimestamp = Date.now() / 1000;
    const isExpired = currentTimestamp > expirationTimestamp;
    if (isExpired) {
      
      toast.info("Token has expired, Please Login again");
      localStorage.removeItem("userData"); 
      window.location.href = `/`;
      window.location.reload()
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export default isTokenExpired;