import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/final.png";
import { delete_cookie } from "sfcookies";
import useAuth from "../../hooks/useAuth";
import { getCookie } from "src/services/cookies";
import { useNavigate } from "react-router-dom"; 
import { LogOut, Bell, Minimize, Search,} from 'react-feather';
import { CheckAllNotification, DeliveryProcessing, } from "src/constant";
 

const Rightbar = (props) => {

  var {auth,setAuth } = useAuth();
  const navigate= useNavigate();

  auth=JSON.parse(localStorage.getItem("userData"))
  const [moonlight, setMoonlight] = useState(false);
  const [searchresponsive, setSearchresponsive] = useState(false)
  const [notificationDropDown, setNotificationDropDown] = useState(false)


  function toCamelCase(inputString) {
    const words = inputString.split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    const camelCaseString = words.join(' ');
    return camelCaseString;
  }

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      // document.querySelector(".search-full").classList.add("open");
      // document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive)
      // document.querySelector(".search-full").classList.remove("open");
    }
  }

   const logout = async() => {

    setAuth({});
    navigate(`/`, { replace: true });
    window.location.reload()
  };

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          {/* <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li> */}
          <li><span className="header-search"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li>
          <li className="onhover-dropdown">
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge badge-pill badge-secondary">2</span></div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
              <li>
                <Bell />
                <h6 className="f-14 mb-0">Notifications</h6>
              </li>
              <li>
                <p><i className="fa fa-circle-o  font-primary" style={{marginRight:10}}> </i>
                Douglas Credit Union Site: Panel Performance is <span style={{color:'red'}} > 82% ( Grade B- ) </span> ; suggested maintenance  <span className="pull-right">{"10 min."}</span></p>
              </li>
              <li><button className="btn btn-primary" >{CheckAllNotification}</button>
              </li>
            </ul>
          </li>

          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
        

          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media d-flex">
              <img src={man} alt="user" />
              <div className="media-body">
                <span > {auth?.username ? auth?.username : ""}</span>
                <p className="mb-0 font-roboto">
                  {auth?.role ? toCamelCase(auth?.role.toString()) : ""} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={logout}>
                <LogOut />
                <span>Logout</span>
              </li>
             
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Rightbar;
