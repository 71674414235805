import "../src/assets/fonts/Proxima-Font-Family/proximanova-black.otf";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthContextProvider from "./contexts/AuthContext";
import RequireAuth from "./components/RequireAuth";
import App from "./components/app";
import "./index.scss";
import {
  addBNewBillRoute,
  AlertEmailsTabRoute,
  billDetailsRoute,
  billsRoute,
  businessesTableRoute,
  contractPreparedRoute,
  contractSignedRoute,
  dashboardRoute,
  designRoute,
  devicesRoute,
  enerygyInsightsRoute,
  hotLeadAdminRoute,
  hotLeadAgentRoute,
  hotLeadDetailsAdminRoute,
  hotLeadDetailsAgentRoute,
  liveProspectSitesAgentRoute,
  liveProspectSitesRoute,
  monitoringRoute,
  NewSiteRoute,
  openSolarRoute,
  prospectDemoAgentRoute,
  prospectDemoRoute,
  publicSolarRoute,
  reportingRoute,
  savedOpportunitiesRoute,
  savedProspectsAgentRoute,
  savedProspectsRoute,
  settingsRoute,
  siteAddBillRoute,
  siteBillDetailsRoute,
  SiteSettingsTabRoute,
  sitesRoute,
  sitesTableRoute,
  mapLiveProspectSitesAgentRoute,
} from "./constant/app_route_names";

// pages
const Monitoring = React.lazy(() => import("./pages/monitoring/monitoring"));
const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));
const MapSites = React.lazy(() => import("./pages/all_sites/map"));
const TableSites = React.lazy(() => import("./pages/all_sites/sites_table"));
const ExcelSites = React.lazy(() => import("./pages/all_sites/new_site_excel"));
const AlertEmails = React.lazy(() => import("./pages/alerts/alertEmails"));
const Settings = React.lazy(() => import("./pages/settings/settings"));

const Reporting = React.lazy(() => import("./pages/reporting/reporting"));
const EnergyInsights = React.lazy(() =>
  import("./pages/energyInsights/energy_insight")
);
const SavedOpportunities = React.lazy(() =>
  import("./pages/energyInsights/saving_opportunites")
);
const Devices = React.lazy(() => import("./pages/settings/devices"));
const OpenSolar = React.lazy(() =>
  import("./pages/settings/opensolar/opensolar")
);
const Logins = React.lazy(() => import("./pages/auth/signin"));
const Error401 = React.lazy(() => import("./pages/errors/error401"));
const Error400 = React.lazy(() => import("./pages/errors/error400"));
const Bills = React.lazy(() => import("./pages/settings/bills/all_bills"));
const AddBill = React.lazy(() => import("./pages/settings/bills/add_bill"));
const BillDetails = React.lazy(() =>
  import("./pages/settings/bills/bill_details")
);
const SolarAPI = React.lazy(() => import("./pages/solarAPI/solar_api_design"));
const DesignScreen = React.lazy(() =>
  import("./pages/settings/design/design_screen")
);
const SiteSettings = React.lazy(() =>
  import("./pages/all_sites/siteSettings/site_settings")
);
const ContractsPrepared = React.lazy(() =>
  import("./pages/saleAgreed/contracts_prepared")
);
const ContractsSigned = React.lazy(() =>
  import("./pages/saleAgreed/contracts_signed")
);
const BusinessesTable = React.lazy(() =>
  import("./pages/prospect/business_table")
);
const ProspectDemo = React.lazy(() => import("./pages/prospect/prospect_demo"));
const SavedProspects = React.lazy(() => import("./pages/prospect/savedProspects"));
const LiveProspectSites = React.lazy(() => import("./pages/prospect/liveSites"));
const HotLeadTable = React.lazy(() => import("./pages/HotLeads/hot_leads_table"));
const HotLeadDetails = React.lazy(() => import("./pages/HotLeads/hot_lead"));

const LocationScreen = React.lazy(() =>
  import("./pages/solarAPI/locationScreen")
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Root = () => {
  return (
    <React.StrictMode>
      <BrowserRouter basename="/">
        <AuthContextProvider>
          <Provider store={store}>
            <React.Suspense fallback={loading}>
              <Routes>
                <Route path="/" element={<Logins />}></Route>
                <Route
                  exact
                  path="/unauthorised"
                  element={<Error401 />}
                ></Route>
                <Route exact path="*" element={<Error400 />} />
                {/* <Route exact path={publicSolarRoute} element={<SolarAPI />} /> */}

                <Route element={<App />}>
                  {/* Admin Routes */}
                  <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                    <Route
                      exact
                      path={dashboardRoute}
                      element={<Dashboard />}
                    />
                    <Route
                      exact
                      path={monitoringRoute}
                      element={<Monitoring />}
                    />
                    {/* All Sites */}
                    <Route exact path={sitesRoute} element={<MapSites />} />
                    <Route
                      exact
                      path={sitesTableRoute}
                      element={<TableSites />}
                    />
                    <Route exact path={NewSiteRoute} element={<ExcelSites />} />
                    <Route
                      exact
                      path={AlertEmailsTabRoute}
                      element={<AlertEmails />}
                    />
                    <Route
                      exact
                      path={SiteSettingsTabRoute}
                      element={<SiteSettings />}
                    />


                    {/* Settings */}
                    <Route exact path={settingsRoute} element={<Settings />} />
                    <Route exact path={devicesRoute} element={<Devices />} />
                    <Route
                      exact
                      path={openSolarRoute}
                      element={<OpenSolar />}
                    />
                    <Route exact path={billsRoute} element={<Bills />} />
                    <Route
                      exact
                      path={addBNewBillRoute}
                      element={<AddBill />}
                    />
                    <Route
                      exact
                      path={siteAddBillRoute}
                      element={<AddBill />}
                    />
                    <Route
                      exact
                      path={billDetailsRoute}
                      element={<BillDetails />}
                    />
                    <Route
                      exact
                      path={siteBillDetailsRoute}
                      element={<BillDetails />}
                    />
                    <Route
                      exact
                      path={designRoute}
                      element={<DesignScreen />}
                    />
                    <Route
                      exact
                      path={businessesTableRoute}
                      element={<BusinessesTable />}
                    />
                    {/* Reports */}
                    <Route
                      exact
                      path={reportingRoute}
                      element={<Reporting />}
                    />
                    <Route
                      exact
                      path={enerygyInsightsRoute}
                      element={<EnergyInsights />}
                    />
                    <Route
                      exact
                      path={savedOpportunitiesRoute}
                      element={<SavedOpportunities />}
                    />

                    {/* Prospect */}
                    <Route exact path={prospectDemoRoute} element={<ProspectDemo />} />
                    <Route exact path={savedProspectsRoute} element={<SavedProspects />} />
                    <Route exact path={liveProspectSitesRoute} element={<LiveProspectSites />} />
                    <Route exact path={hotLeadAdminRoute} element={<HotLeadTable />} />
                    <Route exact path={hotLeadDetailsAdminRoute} element={<HotLeadDetails />} />
                  </Route>

                  <Route element={<RequireAuth allowedRoles={["sales_agent"]} />}>
                  <Route exact path={prospectDemoAgentRoute} element={<ProspectDemo />} />
                  <Route exact path={savedProspectsAgentRoute} element={<SavedProspects />} />
                  <Route exact path={liveProspectSitesAgentRoute} element={<LiveProspectSites />} />
                  <Route exact path={hotLeadAgentRoute} element={<HotLeadTable />} />
                  <Route exact path={hotLeadDetailsAgentRoute} element={<HotLeadDetails />} />
                  <Route
                      exact
                      path={mapLiveProspectSitesAgentRoute}
                      element={<SolarAPI />}
                    />

                    {/* SaleAgreed */}
                    <Route
                      exact
                      path={contractPreparedRoute}
                      element={<ContractsPrepared />}
                    />
                    <Route
                      exact
                      path={contractSignedRoute}
                      element={<ContractsSigned />}
                    />
                  </Route>
                </Route>

                {/* Catch all */}
                <Route exact path="*" element={<Error400 />} />
              </Routes>
            </React.Suspense>
          </Provider>
        </AuthContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
