
import { Fire, Globe2 } from "react-bootstrap-icons";
import {
  Home,
  Map,
  Info,
  BarChart2,
  Settings,
  Bookmark,
  Globe,
} from "react-feather";
import {
  AlertEmailsTabRoute,
  billsRoute,
  contractPreparedRoute,
  contractSignedRoute,
  dashboardRoute,
  designRoute,
  hotLeadAdminRoute,
  hotLeadAgentRoute,
  liveProspectSitesAgentRoute,
  liveProspectSitesRoute,
  NewSiteRoute,
  openSolarRoute,
  prospectDemoAgentRoute,
  prospectDemoRoute,
  reportingRoute,
  savedProspectsAgentRoute,
  savedProspectsRoute,
  settingsRoute,
  sitesRoute,
  sitesTableRoute,
} from "src/constant/app_route_names";

export const ADMIN = [
  {
    menutitle: "General",
    menucontent: "",
    Items: [
      {
        path: dashboardRoute,
        icon: <Home />,
        type: "link",
        active: true,
        title: "Dashboard",
        disabled: false,
      },

      {
        icon: <Map />,
        type: "sub",
        active: false,
        title: "All Sites",
        disabled: false,
        children: [
          {
            path: sitesTableRoute,
            type: "link",
            active: false,
            title: "List View",
            disabled: false,
          },
          {
            path: sitesRoute,
            type: "link",
            active: false,
            title: "Map",
            disabled: false,
          },
          {
            path: NewSiteRoute,
            type: "link",
            active: false,
            title: "Add New Site",
            disabled: false,
          },
          {
            path: AlertEmailsTabRoute,
            type: "link",
            active: false,
            title: "Alert Emails",
            disabled: false,
          },
        ],
      },
      {
        icon: <Info />,
        type: "sub",
        active: false,
        title: "Prospect",
        disabled: false,
        children: [
          {
            path: prospectDemoRoute,
            type: "link",
            active: false,
            title: "Search Prospects",
            disabled: false,
          },
          {
            path: savedProspectsRoute,
            type: "link",
            active: false,
            title: "Saved Prospects",
            disabled: false,
          },

          {
            path: liveProspectSitesRoute,
            type: "link",
            active: false,
            title: "Live Sites",
            disabled: false,
          },
        ],
      },

      {
        path: hotLeadAdminRoute,
        icon: <Fire size={20} />,
        type: "link",
        active: true,
        title: "Hot Lead",
        disabled: false,
      },

      {
        icon: <Settings />,
        type: "sub",
        active: false,
        title: "Settings",
        disabled: false,
        children: [
          {
            path: settingsRoute,
            type: "link",
            active: false,
            title: "System Setup",
            disabled: false,
          },
          {
            path: openSolarRoute,
            type: "link",
            active: false,
            title: "Opensolar",
            disabled: false,
          },

          {
            path: billsRoute,
            type: "link",
            active: false,
            title: "Bills",
            disabled: false,
          },
          {
            path: designRoute,
            type: "link",
            active: false,
            title: "Solar Potential",
            disabled: false,
          },
        ],
      },

      {
        path: reportingRoute,
        icon: <BarChart2 />,
        type: "link",
        active: false,
        title: "Reporting",
        disabled: false,
      },
    ],
  },
];

export const SALESAGENT = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        icon: <Info />,
        type: "sub",
        active: true,
        title: "Prospect",
        disabled: false,
        children: [
          {
            icon: Map,
            // icon: <Map />,
            path: prospectDemoAgentRoute,
            type: "link",
            active: true,
            title: "Search Prospects",
            disabled: false,
          },
          {
            icon: Bookmark,
            // icon: <Bookmark />,
            path: savedProspectsAgentRoute,
            type: "link",
            active: false,
            title: "Saved Prospects",
            disabled: false,
          },

          {
            icon: Globe,
            // icon: <Globe />,
            path: liveProspectSitesAgentRoute,
            type: "link",
            active: false,
            title: "Live Sites",
            disabled: false,
          },
        ],
      },
     
      {
        path: hotLeadAgentRoute,
        icon: <Fire size={20} />,
        type: "link",
        active: true,
        title: "Hot Lead",
        disabled: false,
      },
      {
        // path: sitesRoute,
        icon: <Map />,
        type: "sub",
        active: false,
        title: "Contracts",
        disabled: false,
        // path:sitesTableRoute,
        children: [
          {
            path: contractPreparedRoute,
            type: "link",
            active: false,
            title: "Contracts Prepared",
            disabled: false,
          },
          {
            path: contractSignedRoute,
            type: "link",
            active: false,
            title: "Contracts Signed",
            disabled: false,
          },
        ],
      },
    ],
  },
];

export const Manager = [
  {
    menutitle: "",
    menucontent: "",
    Items: [],
  },
];
